/* eslint-disable */
import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import {
  Banner,
} from 'components';
import { BASE_URL } from 'config';

const Preparing = () => (
  <Layout page="preparing">

    <Helmet
      title="Tips and Tricks to Prepare for your New Zealand Trip"
      meta={[
        {
          name: 'description', content: 'Hints and Tips to prepare for your NZ trip',
        },
        { name: 'og:title', content: 'Tips and Tricks to Prepare for your New Zealand Trip' },
        {
          name: 'og:description', content: 'Hints and Tips to prepare for your NZ trip',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/preparing/` }]}
    />

    <Banner backgroundImage="https://static-hakatours.imgix.net/destinations-bg.jpg?fit=crop&auto=format" />
    <section className="l-section">
      <div className="l-container">
        <h2 className="l-contact-us__title c-heading c-heading--h2">Preparing for your New Zealand tour</h2>
        <p>
          To help you get ready for the ultimate NZ adventure tour, here are some tips and pointers.
          We will also send you out heaps of helpful information with your Final Tour Documentation. The first thing to prepare for is to be wowed and amazed.
          New Zealand is as awesome as they say! We’ve put together a list of things to bring (and things to leave at home) to help you get packing.
          We also give some guidance on what to expect on a day to day basis and the role our awesome guides will play in your trip! We guarantee you will leave one of our tours with heaps of amazing experiences,
          unreal stories and some killer pics! All we ask is that you bring your (try anything once) attitude. We provide all specialist gear so no need to bring a jump-suit for your skydive!
        </p>
        <br />
        <h2 className="l-contact-us__title c-heading c-heading--h2">Preparation before you leave home</h2>
        <p>
          <b>Passport and Visa:</b> You need a passport that is valid for at least 3 months beyond the date you intend to leave New Zealand,
          and evidence of return or ongoing travel past New Zealand (to be presented at immigration on arrival).
          From 1 October 2019, travellers must have an NZeTA to travel to New Zealand. From July 2019, travellers can request their NZeTA via the NZeTA mobile app or the Immigration NZ website.
          Australian Citizens do not need to apply for a visa. For full details see <a href="http://files.hakatours.com/haka-tours-visa-entry.pdf" target="_blank">our Visa Flyer</a> or
          visit <a href="https://www.immigration.govt.nz/new-zealand-visas" target="_blank">www.immigration.govt.nz</a>.
          <br /><br />
          <b>Credit card(s)</b> – widely accepted in NZ, in particular  Visa, Master Card and Bankcard. International credit cards and ATM cards from major banks will work for cash withdrawal as long as
          they have a four-digit PIN encoded. However, purchases cannot be made with international ATM cards via EFTPOS. We recommend you check with your bank and inform them of where/when you are going.
          <br /><br />
          <b>Cash:</b> $200 NZD in cash for your first few days, just in case you experience difficulties withdrawing or exchanging money on arrival.
          <br /><br />
          <b>Insurance:</b> Travel insurance is mandatory for all our travellers and must be taken out at the time of booking.
          Your travel insurance must provide cover against personal accident, death, medical expenses and emergency repatriation with
          a recommended minimum coverage of US$200,000 for each of the categories of cover. More details can be found in our booking terms and conditions. We recommend
          <a href="https://www.worldnomads.com/Turnstile/AffiliateLink?partnerCode=hakat0ur5&source=&utm_source=hakat0ur5&utm_content=link&path=https://www.worldnomads.com/travel-insurance/" rel="noopener noreferrer" target="_blank"> World Nomads</a>.
          <br /><br />
          <b>Electric plug adaptors:</b> These can be bought at international airports. Electrical current is supplied domestically at 230 volts, 50 hertz. Power sockets accept three-pin flat plugs.
          <br /><br />
          <b>Packing:</b> Due to limited space you are required to keep your luggage to one reasonable sized suitcase or backpack,
          with a maximum weight of 23kg, and a small day bag. Please make sure all your baggage is insured and
          identifiable. Should you wish to bring anything over this limit, please get in touch with your tour advisor
          before departing.
          <br /><br />
          Pack light! Clothing should only need to be casual and comfortable. (See weather for more information.) You will need to include a towel and toiletries as these are not included at where we stay.
          <br /><br />
          Don’t forget basic medical supplies, e.g. painkillers, plasters, insect repellent/treatment, anti-bacterial cream sunscreen, etc.
        </p>
        <br />
        <h2 className="l-contact-us__title c-heading c-heading--h2">What to expect on a day-to-day basis</h2>
        <p>
          The earliest time we expect you to be up and out of <a href="https://hakatours.com/accommodation-on-tour" target="_blank" rel="noopener noreferrer">the accommodation </a>
          by is 7am (when we have to catch the ferry from Wellington to Picton on the <a href="https://hakatours.com/new-zealand-tours/two-weeks" target="_blank" rel="noopener noreferrer">16 </a>
          and <a href="https://hakatours.com/new-zealand-tours/three-weeks" target="_blank" rel="noopener noreferrer">23 days</a> tours). Other than this we normally have breakfast between 8 and 9am.
          On (free days) there is sometimes the opportunity for a longer lie-in but we like to make the most of your time!
          Journey times can vary between an hour to occasionally 4 or 5 on the road. However,
          don’t sweat because <a href="https://hakatours.com/guides/new-zealand-adventure" target="_blank" rel="noopener noreferrer">your tour guide</a> will
          break up every drive with heaps of unique stops to keep you entertained and show you as much of our beautiful country as possible.
          Don’t expect an hour-by-hour constant schedule as we like to keep some spontaneity involved! However,
          your <a href="https://hakatours.com/guides/" target="_blank" rel="noopener noreferrer">tour guide</a> will make sure you know anything important that is planned ahead.
          They will also provide the odd surprise along the way.
          On (free days) when we don’t travel between locations there is always plenty to see and do! Your guide will give you a guided tour of the area, and is always open to what tour members wish to focus their day upon.
          You are of course still free to do your own thing and do not have to stay with the group - your guide will be full of suggestions and won’t ever force you into doing anything you don’t want to do.
          <br /><br />
          <b>Breakfast</b> Breakfast is included every day of the tour, and includes staples such as bread for toast, cereal, fresh fruits, eggs, yoghurts, spreads and tea/coffee.
          Please do let us know if you have any specific breakfast requirements and we’ll do our best to meet these.
          <br /><br />
          <b>Lunch and dinner</b> Lunch and dinner are not provided so you have two choices - you can buy food and make it at the hostel (all have kitchen facilities) or you can eat out. Your guide will always have suggestions.
          Sometimes the place you stay at will offer dinner for a very reasonable price as well.
          <br /><br />
          More often than not, a tour party will chose to eat together, often cooking as a group and sharing the cost of food.
          If you are lucky your guide may treat you all and cook up freshly caught seafood and whilst on the road we have a number of places we like to picnic at.
          <br /><br />
          In the evening there is always an opportunity to socialise but again it is completely optional.
          Some tour members will like to grab a few beers or wines whilst others will rather chill out in the hostel and have an early night.
          If you do ever fancy a night on the town, adrenalin and extreme activities are always the best hangover cure.
          <br /><br />
          <b>Activities</b> At the start of your tour, your Tour Manager will discuss any activities you may have pre-booked and give you the opportunity to add anything else on you may wish to do.
          <br /><br />
          It is our promise to do our best to allow you to do as much or as little as you want whilst you are touring with us.  Activities booked when on tour will be charged at the standard retail rate.
          We operate a voucher system with our activity partners which means we pay in full via a (voucher) for any optional activities instead of you paying the activity provider direct.
          <br /><br />
          We then add any activities that you have selected to your very own (Activity Balance Sheet) with the full balance taken at the end of the tour or when your activity balance exceeds $500 NZD.
          By operating a voucher system with our activity suppliers, we are able to better manage tour logistics.
          <br /><br />
          Your activity balance can be paid via MasterCard, Visa or cash. Credit card payments have a 1.5% credit card processing fee. Haka Tours does not qualify for any group booking discount with our activity suppliers.
          <br /><br />
          <b>Cancellations:</b>  Please note that all activities are subject to weather conditions and therefore there may be an occasion or two when they are cancelled. Should this happen,
          your Tour Manager will do everything he can to find you a suitable alternative (whether this be a different activity in the same place and/or the same activity on a different day).
          <br /><br />
          As a final resort we are happy to refund you in full for anything cancelled due to weather conditions. When a pre-booked activity is cancelled by you,
          there may be cancellation costs involved, and so we suggest you should check with your Tour Manager before making a decision.
        </p>
        <br />
        <h2 className="l-contact-us__title c-heading c-heading--h2">What to expect from your tour guide</h2>
        <p>
          Passion, knowledge, organisation, and a good friend. Your tour guide is there to provide you with an outstanding service and show you everything New Zealand has to offer!
          <a href="https://hakatours.com/guides/new-zealand-adventure" target="_blank" rel="noopener noreferrer"> All our guides </a>
          are NZ experts: bursting with energy and information about their country and all our activities. Don’t be afraid to ask lots of questions and make full use of their expertise - you wouldn’t get this service if you were travelling alone.
          <br /><br />
          Each of our guides has their own favourite places and extras that they like to show and do with their groups.
          <br /><br />
          Finally, we do ask that you realise your guide cannot be expected to work 24/7, and therefore will need a break in the evenings to relax after their busy day.
          We can promise they will go out of their way to make sure you have a great time, but don’t forget they are also human.
        </p>
        <br />
        <h2 className="l-contact-us__title c-heading c-heading--h2">What else to budget for</h2>
        <p>
          There are no hidden costs when travelling with Haka Tours. We suggest that you consider N$30-60 for food (i.e. lunch and dinner together) per day, depending on how expensive your tastes may be.
          <br /><br />
          Some activity suppliers will offer you photos or DVDs of your time with them, in particular skydiving, canyon swing, quad biking, rafting, and bungy (like the below). This can vary from $30–100 and is completely optional.
          <br /><br />
          Allow money for souvenirs should you wish to bring back anything and always get travel insurance.
        </p>
        <br />
        <h2 className="l-contact-us__title c-heading c-heading--h2">NZ Weather</h2>
        <p>
          New Zealand is known for the ability to have 4 different seasons in 1 day so we ask you to be prepared for all conditions. The top of the North Island is considered subtropical,
          so expect more humid conditions in summer. The South Island will be much colder than the North in winter.
        </p>
        <br />
        <h2 className="l-contact-us__title c-heading c-heading--h2">Our seasons</h2>
        <p>
          <b>Spring</b> - October to December: varying temperatures with cooler temperatures in the evenings and out of the sun. December can be as warm as the summer months on a good year.
          <br /><br />
          <b>Summer</b> - January to March: temperatures usually from 20 - 35 degrees Celsius in the North, with the occasional cooler day in the South. Sun screen is essential as you can burn even in 10 minutes.
          <br /><br />
          <b>Autumn</b> - April to mid June: getting cooler with more chance of rain, especially in the West Coast… but stunning Autumnal colours.
          <br /><br />
          <b>Winter</b> - June to September: colder temperatures, occasionally dropping below freezing. Snow is common in places especially in the South but rain remains relatively infrequent
        </p>
        <br />
        <h2 className="l-contact-us__title c-heading c-heading--h2">Other Important Information</h2>
        <p>
          <b>Medical Treatment - ACC</b> : If you are injured whilst in New Zealand, <a href="http://www.acc.co.nz/" target="_blank" rel="noopener noreferrer"> ACC </a>
          may be able to help with the cost of the treatment and support you need whilst you’re here.
          However, it is important to be aware that you cannot sue for personal injury - ACC replaces that right. Furthermore, ACC only covers treatment and rehabilitation costs whilst in New Zealand;
          it is not a replacement for travel insurance and does not cover illness, disrupted travel plans or emergency travel to get you back home.
          <br /><br />
          <b>And Lastly:</b> Please ask your <a href="mailto:info@hakatours.com">Haka Tour Advisor</a> any final questions before you leave so we can ensure you’re fully prepared for one awesome New Zealand experience!
          Haka Tours fully commits to providing you with the most EPIC of New Zealand tours. As with all tour parties,
          we will send you out a customer survey after your tour has finished as we are very interested in your feedback and experiences to keep ensuring Haka keeps at the fore of adventure NZ touring!
        </p>
      </div>
    </section>
  </Layout>
);

export default Preparing;
